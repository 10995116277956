import React, { Fragment, useCallback,  useEffect,  useRef, useState } from 'react';
import Map from '../Map';
import MarkerIcon from '../../images/marker.png';
import MarkerIconAlt from '../../images/marker-alt.png';
import { MarkerF } from '@react-google-maps/api';
import * as s from './MapSection.module.scss';
import { useFilter } from '../providers/FilterProvider';
import { PrismicLink } from '@prismicio/react';
import ProjectPreviewCard from '../cards/ProjectPreviewCard';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useMeta } from '../providers/MetaProvider';


const MapSection = () => {
  const {schools} = useFilter();
  const {labels, meta} = useMeta();
  const [selectedSchool, setSelectedSchool] = useState(null);

  const country = selectedSchool?.Country.length === 1 ? selectedSchool?.Country[0] : null;
  const countryName = country?.data?.Name || ""
  const countryImage = getImage(country?.data?.Flag?.localFiles[0]?.childImageSharp) || null;

  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    const bounds = new window.google.maps.LatLngBounds();
    schools.forEach(({ Coordinates }) => {
      bounds.extend(Coordinates);
    });
    map.fitBounds(bounds);
  }, [schools]);

  useEffect(() => {
    if (mapRef.current && schools.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      schools.forEach(school => {
        bounds.extend(new window.google.maps.LatLng(school.Coordinates.lat, school.Coordinates.lng));
      });
      mapRef.current.fitBounds(bounds);
      
      const center = bounds.getCenter();
      mapRef.current.setCenter(center);

      const zoom = mapRef.current.getZoom();
      mapRef.current.setZoom(zoom > 1 ? zoom - 12 : 0);
    }

    // Wait for the map to load
    const mapLoadedInterval = setInterval(() => {
      const button = document.querySelector('div[role="button"]');
      if (button) {
        // Add the accessible name
        button.setAttribute('aria-label', 'Descriptive label');
        clearInterval(mapLoadedInterval);
      }
    }, 1000);
  
    if (selectedSchool && !schools.find(school => school.Name === selectedSchool.Name)) {
      setSelectedSchool(null);
    }
    //eslint-disable-next-line
  }, [schools])

  const handleMarkerClick = (school) => {
    setSelectedSchool(school);
    if (mapRef.current) {
      const map = mapRef.current;
      map.panTo(school.Coordinates);
    }
  };


  return (
    <section className={s.mapContainer}>
      <div style={{position: 'relative'}} aria-label="School location map">
        <Map
          mapContainerStyle={{
            width: '100%',
            height: '80vh',
          }}
          onClick={() => {
            setSelectedSchool(null);
          }}
          onLoad={onLoad}
          options={{ 
            styles: mapStyle,
            restriction: {
              latLngBounds: {
                north: 85,
                south: -70,
                west: -180,
                east: 180
              },
              strictBounds: true,
            },
            disableDefaultUI: true, 
            fullscreenControl: false, 
            zoomControl: true, 
            keyboardShortcuts: false
          }}
        >
          {schools.map((school, index) => (
            <MarkerF
              key={index}
              className={s.marker}
              position={school.Coordinates}
              icon={selectedSchool === school ?
                 MarkerIconAlt : 
                 MarkerIcon}
              onClick={() => {
                handleMarkerClick(school); 
              }}
            />
          ))}
        </Map>
        <div>
          {/* School info Box */}
          {selectedSchool && (
            <div className={s.infoWindow}>
              <div className={s.scrollWrap}>
                <div>
                  <h3>{selectedSchool.Name}</h3>
                  <div className={s.CountryWrap}>
                  {countryImage && 
                    <div style={{maxWidth: 25, display: 'flex', alignItems: 'center', paddingBottom: 2}}>
                      <GatsbyImage
                        image={countryImage}
                        style={{ borderRadius: 3, height: 'auto', width: '100%'}}
                        alt="Country Flag"
                      />
                    </div>
                  }
                  <p><strong>{selectedSchool.City}, {countryName}</strong></p>
                </div>
                  <PrismicLink href={selectedSchool.Link} className={s.buttonPrimary}>
                    {meta.view_school}
                  </PrismicLink>
                </div>
                <div>
                  <h4>{labels.schools_projects}</h4>
                  {selectedSchool?.Projects && (
                    <div>
                      {selectedSchool.Projects.map(({data}, index) => (
                        <Fragment key={index}>
                          <ProjectPreviewCard
                            project={data}
                          />
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default MapSection;


const mapStyle = [
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      { "color": "#145A51" }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [{ visibility: "off" }]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      { "color": "#0a9583" }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      { "color": "#0a9583" }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "labels",
    "stylers": [
      { "visibility": "off" }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels",
    "stylers": [
      { "visibility": "off" }
    ]
  }
];