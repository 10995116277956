import React from 'react';
import { PrismicLink } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useMeta } from '../providers/MetaProvider';
import { FaChevronRight, FaPencilRuler } from "react-icons/fa";
import * as s from './CardStyle.module.scss';
import { slugify } from '../../utils/helperFunctions';
import { AirtableImage, isImage } from '../AirtableImage';

const ProjectCard = ({project, style}) => {
  const {meta} = useMeta();

  if(!project) return null;
  const {
    Name,
    Logo,
    Description,
    Full_Sdgs,
    FFS_Alignments,
    Link,
  } = project;
  const values = FFS_Alignments?.join(', ');
  
  const link = Link?.url || `/project/${slugify(Name)}`;

  return (
    <div className={s.Card} style={style}>
      <PrismicLink className={s.logoImage} href={link}>
      {isImage(Logo) ? 
        <AirtableImage
          image={Logo}
          alt={'Project Logo'}
        />
          : <div className={s.placeholder}><FaPencilRuler/></div>
        }
      </PrismicLink>
      <PrismicLink href={link} style={{textDecoration: 'none'}}><h3>{Name}</h3></PrismicLink>
      <div className={s.sdg}><p><strong>{meta.sdgs}:</strong></p>
        <span>
          {Full_Sdgs?.map(({ data }) => (
            <GatsbyImage
              key={data?.Icon?.localFiles[0].publicURL}
              image={data?.Icon?.localFiles[0].childImageSharp?.gatsbyImageData}
              style={{maxWidth: '50px'}}
              alt="SDG Icon"
            />
          ))
          
          }
        </span>
      </div>
      <p className={s.values}><strong>{meta.values}:</strong> {values}</p>
      <p className={s.truncate}>{Description}</p>

      <PrismicLink href={link} className={s.button}>
        {meta.learn_more}
        <FaChevronRight />
      </PrismicLink>
    </div>
  );
};

export default ProjectCard;