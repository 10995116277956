import { slugify } from "./helperFunctions";


function uniqueFlatten(arr) {
  if(!arr) return [];
  return [...new Set(arr.flat())];
}

const keyMergeProject = (project) => {
  if(!project || !project.data) return;
  const { data } = project;
  let sdgs = [];
  let regions = [];
  data.Full_Sdgs?.forEach(({ data }) => {
    if(data.Title) sdgs.push(data.Title);
  });
  data.School_s_?.forEach(({ data }) => {
    if(data.Region) regions.push(data.Region);
  });
  return {
    ...data,
    Link: `/project/${slugify(data.Name)}`,
    Values: data.FFS_Alignments,
    Sdgs: uniqueFlatten(sdgs),
    Regions: uniqueFlatten(regions),
    
  }
}

const keyMergeSchool = (school) => {
  if(!school || !school.data) return;
  const { data } = school;
  let values = [];
  let sdgs = [];
  data.Projects?.forEach(({ data }) => {
    if(data.FFS_Alignments) values.push(data.FFS_Alignments);
    if(data.SDGs) {
      data.SDGs.forEach(({ data }) => {
        if(data.Title) sdgs.push(data.Title);
      });
    }
  });
  return {
    ...data,
    Link: `/school/${slugify(data.Name)}`,
    Values: uniqueFlatten(values),
    Sdgs: uniqueFlatten(sdgs),
    Regions: data.Region,
    Coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)}
  }
}
const keyMergeSchools = (schools) => {
  return schools.map(x => keyMergeSchool(x));
}
const keyMergeProjects = (projects) => {
  return projects.map(x => keyMergeProject(x));
}
export { keyMergeProjects, keyMergeSchools}